import React, { useState, useEffect } from 'react'
import { BsPlusLg } from 'react-icons/bs'
import { AiOutlineMinus } from 'react-icons/ai'
import Footer from '../components/homeNav/footer/Footer'
import Drug from '../images/medicineicon.png'
import TextField from '@material-ui/core/TextField'
import BotNav from '../components/bottomNav'
import useAuthState from '../stores/auth'
import useCartState from '../stores/cart'
import {
  Container,
  Wrapper,
  Title,
  Top,
  TopButton,
  TopTexts,
  TopText,
  BottomCart,
  Info,
  Product,
  ProductDetail,
  Image,
  Details,
  ProductName,
  PriceDetail,
  ProductAmountContainer,
  ProductAmount,
  ProductPrice,
  Hr,
  Summary,
  SummaryTitle,
  SummaryItem,
  SummaryItemText,
  SummaryItemPrice,
  CheckOutButton,
} from '../style/cartstyles'

const Cart = () => {
  const { cart, setCart, increment, decrement, getCartLength, salesTotal } =
    useCartState()

  const { isLoggedIn } = useAuthState()

  const setQuantity = (itemIndex, e) => {
    const newCart = [...cart]
    newCart[itemIndex].quantity = e
    setCart(newCart)
  }

  return (
    <>
      <Container>
        <Wrapper>
          <Title>Your Cart</Title>
          <Top>
            <TopButton to="/pharmacy">Continue Shopping</TopButton>
            <TopTexts>
              <TopText>Cart({getCartLength()})</TopText>
              {/* <TopText> Wishlist (0)</TopText> */}
            </TopTexts>
            {!!getCartLength() && isLoggedIn() && (
              <TopButton to="/Checkout" type="filled">
                Checkout Now
              </TopButton>
            )}
          </Top>
          <BottomCart>
            <Info>
              {cart.map((item, index) => {
                return (
                  <span key={index}>
                    <Product key={index}>
                      <ProductDetail>
                        <Image src={item.image || Drug} />
                        <Details>
                          <ProductName>
                            <b>Product:</b>{' '}
                            {item.type === 'pharmaceutical'
                              ? item.customBrandName
                              : `${item.productName} ${item.packSize}`}
                          </ProductName>
                          <span>
                            <b>Per:</b>
                            {item.unit}
                          </span>
                          {!!item.discount.value && (
                            <ProductName>
                              <b>Discount:</b>
                              {item.discount.value}%
                            </ProductName>
                          )}
                        </Details>
                      </ProductDetail>
                      <PriceDetail>
                        <ProductAmountContainer>
                          <BsPlusLg
                            onClick={() => increment(index)}
                            style={{ color: '#198754', cursor: 'pointer' }}
                          />
                          <ProductAmount>
                            <TextField
                              id="CartQuantity"
                              name="CartQuantity"
                              autoFocus
                              value={item.quantity}
                              onChange={e => {
                                setQuantity(index, e.target.value)
                              }}
                            />
                          </ProductAmount>

                          <AiOutlineMinus
                            onClick={() => decrement(index)}
                            style={{ color: '#198754', cursor: 'pointer' }}
                          />
                        </ProductAmountContainer>
                        {item.showPrice && (
                          <ProductPrice>
                            @ksh{' '}
                            {!!item.discount?.value
                              ? item.sellingPrice *
                                ((100 - item.discount.value) / 100)
                              : item.sellingPrice}
                          </ProductPrice>
                        )}
                        {item.showPrice && (
                          <ProductPrice>
                            ksh{' '}
                            {!!item.discount?.value
                              ? item.quantity *
                                item.sellingPrice *
                                ((100 - item.discount.value) / 100)
                              : item.quantity * item.sellingPrice}
                          </ProductPrice>
                        )}
                      </PriceDetail>
                    </Product>
                    <Hr />
                  </span>
                )
              })}
            </Info>
            <Summary>
              <div
                style={{
                  borderRadius: '8px 8px 0 0',
                  backgroundColor: '#edf9ee',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px 20px',
                }}
              >
                <SummaryTitle>Order Summary</SummaryTitle>
              </div>
              <div style={{ padding: '20px' }}>
                <SummaryItem type="total">
                  <SummaryItemText>Total:</SummaryItemText>
                  <SummaryItemPrice>ksh {salesTotal}</SummaryItemPrice>
                </SummaryItem>
                {isLoggedIn() && !!getCartLength() && (
                  <CheckOutButton to="/Checkout">Checkout Now</CheckOutButton>
                )}
                {!isLoggedIn() && !!getCartLength() && (
                  <CheckOutButton to="/Login">Login to Checkout</CheckOutButton>
                )}
              </div>
            </Summary>
          </BottomCart>
        </Wrapper>
      </Container>
      <BotNav />
      <Footer />
    </>
  )
}

export default Cart
