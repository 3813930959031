/* eslint-disable no-underscore-dangle */
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

function getSalesTotal(cart) {
  const sum = cart.reduce((previousValue, currentValue) => (
    previousValue
      + (currentValue.showPrice ? currentValue.sellingPrice : 0)
        * currentValue.quantity
        * (currentValue.discount?.value
          ? (100 - currentValue?.discount.value) / 100
          : 1)
  ), 0);
  return sum;
}

const useCartState = create(
  persist(
    (set, get) => ({
      cart: [],
      getCartLength: () => get().cart.length,
      salesTotal: 0,
      setCart: (cart) => set(() => ({ cart, salesTotal: getSalesTotal(cart) })),
      increment: (index) => {
        const updatedCart = [...get().cart];
        updatedCart[index].quantity += 1;
        set(() => ({
          cart: updatedCart,
          salesTotal: getSalesTotal(updatedCart),
        }));
      },
      decrement: (index) => {
        const updatedCart = [...get().cart];

        if (parseInt(updatedCart[index].quantity) === 1) {
          updatedCart.splice(index, 1);
        } else {
          updatedCart[index].quantity -= 1;
        }
        set(() => ({
          cart: updatedCart,
          salesTotal: getSalesTotal(updatedCart),
        }));
      },
    }),
    {
      name: 'cart',
    },
  ),
);

export default useCartState;
